import React from 'react'
//import serviceIcon7 from '../../assets/images/services/service-icon7.png'
// import serviceIcon8 from '../../assets/images/services/service-icon8.png'
// import serviceIcon9 from '../../assets/images/services/service-icon9.png'
// import serviceIcon10 from '../../assets/images/services/service-icon10.png'
// import serviceIcon11 from '../../assets/images/services/service-icon11.png'
// import serviceIcon12 from '../../assets/images/services/service-icon12.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'
import gestiondoc from '../../assets/images/icones/gestion-doc.gif'
import risque from '../../assets/images/icones/risque.gif'
import nc from '../../assets/images/icones/n-c.gif'
import decisions from '../../assets/images/icones/decisions.gif'

const Content = () => {
    return (
        <section className="featured-services-area ptb-70">
            <div className="container">
                <div className="upcoming-bootcamps-content bottom">
                    <h2 className="h2-top bottom">Centralisez la gestion de vos données sur un seul outil</h2>
                    <p className='bold center'>Gagnez en efficacité en optimisant la gestion de votre système de management !</p>
                    <br></br>
                </div>
            </div>


            <div className='container custom-box'>
                <div className="row" id="qhse">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={gestiondoc} alt="services" />
                            </div>
                            <h3>
                            Gestion documentaire
                            </h3>
                            <p><span className='bold'>Centralisez votre base de données :</span> manuels qualité, procédures, fiches techniques, formulaires, etc.<br></br>
                            Communiquez simplement vos documents en <span className='bold'>temps réel</span> et en un seul clic à l’ensemble de vos collaborateurs afin qu’ils travaillent avec les dernières versions mises à jour.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Bibliothèque de procédures</h4>
                                            <h4><i className="flaticon-tick"></i> Bibliothèque de modèles de questionnaires</h4>
                                            <h4><i className="flaticon-tick"></i> Mise à jour des documents</h4>
                                            <h4><i className="flaticon-tick"></i> Publications de fichiers textes, images et vidéos</h4>
                                            <h4><i className="flaticon-tick"></i> Édition et partage de vos documents</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon border-qhse">
                                <img src={nc} alt="services" />
                            </div>
                            <h3>
                            Gestion des non-conformités, audits et plans d’actions
                            </h3>
                            <p>Collectez et traitez rapidement vos remontées d’informations concernant les non-conformités et dysfonctionnements grâce aux <span className='bold'>formulaires numériques</span>. Réalisez vos audits et planifiez automatiquement vos actions correctives et préventives avec le système de <span className='bold'>plans d’actions intégrés</span>.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Enregistrer les non-conformités</h4>
                                            <h4><i className="flaticon-tick"></i> Mettre en place et suivre les actions d’amélioration (correctives et préventives)</h4>
                                            <h4><i className="flaticon-tick"></i> Affecter automatiquement des tâches aux acteurs</h4>
                                            <h4><i className="flaticon-tick"></i> Être notifié des échéances</h4>
                                            <h4><i className="flaticon-tick"></i> Planifier et suivre les audits</h4>
                                            <h4><i className="flaticon-tick"></i> Planifier et suivre les plans d’action</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon border-qhse">
                                <img src={risque} alt="services" />
                            </div>
                            <h3>
                            Gestion des risques
                            </h3>
                            <p>Risques internes ou externes, il est primordial pour chaque entreprise de <span className='bold'>maîtriser ses risques</span>. Afin d’en limiter les impacts, il est essentiel de fournir à vos collaborateurs les bons outils pour <span className='bold'>prévenir, suivre et contrôler</span> les situations à risque. Le choix d’une solution digitale vous permet de répondre rapidement à vos besoins au quotidien.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Créer votre propre formulaire digital du DUER (document unique d’évaluation des risques professionnels)</h4>
                                            <h4><i className="flaticon-tick"></i> Collecter et suivre les indicateurs en prévention SST (santé et sécurité au travail) et RPS (risques psycho-sociaux)</h4>
                                            <h4><i className="flaticon-tick"></i> Planifier et gérer les plans d’action de prévention</h4>
                                            <h4><i className="flaticon-tick"></i> Gestion des fiches de sécurité au poste</h4>
                                            <h4><i className="flaticon-tick"></i> Gestion des visites de sécurité</h4>
                                            <h4><i className="flaticon-tick"></i> Gestion des prestataires</h4>
                                            <h4><i className="flaticon-tick"></i> Suivi des retours d’évaluations des clients, fournisseurs et partenaires</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={decisions} alt="services" />
                            </div>
                            <h3>
                            Outil de pilotage et de prise de décision
                            </h3>
                            <p>La plateforme permet de simplifier vos processus de management avec un <span className='bold'>suivi en temps réel et en continu de vos indicateurs de performance</span>. Un tableau de bord qui vous donne une vision globale de vos données pour optimiser vos prises de décision.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Tableau de bord qui centralise vos données</h4>
                                            <h4><i className="flaticon-tick"></i> Suivi des indicateurs de performance en temps réel</h4>
                                            <h4><i className="flaticon-tick"></i> Traçabilité de vos process et procédures</h4>
                                            <h4><i className="flaticon-tick"></i> Facilite la préparation des réunions et revues de direction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </section>
    )
}

export default Content
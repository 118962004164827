import React from 'react'
import { Link } from 'gatsby'
import img10 from '../../assets/images/about/about-img10.png'
import shape1 from '../../assets/images/about/about-shape1.png'
import starIcon from '../../assets/images/star-icon.png'

const ModQHSE = () => {
    return (
        <div className="about-area ptb-70 bg-qhse">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={img10} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Wispeek QHSE
                                </span>
                                <h2>Votre centre de pilotage des processus QHSE</h2>
                                <p>Notre plateforme Saas, 100% web, s’adapte à tous types d’organisations quel que soit votre secteur d’activité. Elle <span className='bold'>est conforme à vos référentiels métiers</span> et aux différentes exigences réglementaires ISO.<br></br><br></br>
                                Ergonomique et personnalisable, <span className='bold color-compliance'>Wispeek</span> est conçue pour <span className='bold'>gérer et améliorer l’efficacité de votre système de management</span>.<br></br><br></br>
                                Pour vous offrir une visibilité globale et en temps réel de tous vos évènements, documents, processus et plans d’action.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez une démo <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModQHSE
import React from 'react'
//import { Link } from 'gatsby'
import wispeek from '../../assets/images/home/wispeek.png'
import shape1 from '../../assets/images/about/about-shape1.png'

const ContentTwo = () => {
    return (
        <div className="about-area ptb-70 bg-qhse">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={wispeek} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Libérez-vous des tâches administratives chronophages et travaillez de partout et à tout moment</h2>
                                <p>Automatisez certaines tâches récurrentes à l’aide d’un logiciel qualité !</p>
                                <p>La gestion d’un système QHSE est complexe, quelle que soit la taille de votre organisation. Le choix d’une solution digitale vous apporte une réelle agilité dans le pilotage de vos processus et vous offre de nombreux <span className='bold'>avantages stratégiques qui vous seront utiles sur le long terme</span>.</p>
                                <p><span className='bold'>Accessible en mode déconnecté</span>, pour assurer la continuité de l’activité et faciliter le pilotage de votre performance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentTwo
import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModQHSE from "../components/QHSE/ModQHSE"
import WhyWispeek from "../components/QHSE/WhyWispeek"
import CartesAvantages from "../components/QHSE/CartesAvantages"
import Content from "../components/QHSE/Content"
import ContentTwo from "../components/QHSE/ContentTwo"
import { Helmet } from "react-helmet"


const QHSE = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wispeek by Seekreet | Centre de pilotage des process QHSE</title>
        <meta name="description" content="Centraliser, simplifier et maîtriser votre système de management QHSE : collecte, partage, analyse, édition et archivage des données, alimenter vos démarches de progrès continu." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Wispeek - QHSE & Qualité" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Wispeek" 
          parentPageUrl="/wispeek"
          activePageText="QHSE - Qualité" 
      />
      <ModQHSE />
      <Content />
      <ContentTwo />
      <WhyWispeek />
      <CartesAvantages />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default QHSE
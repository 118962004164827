import React from 'react'
//import service4 from '../../assets/images/services/service4.png'
//import service5 from '../../assets/images/services/service5.png'
//import service6 from '../../assets/images/services/service6.png'
//import product1 from '../../assets/images/services/product1.png'
import img1 from '../../assets/images/banner-img3.png'
import starIcon from '../../assets/images/star-icon.png'

const WhyWispeek = () => {
    return (
        <section className="overview-area ptb-70">
            <div className="container">

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Pourquoi nous ?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>Pourquoi choisir Wispeek ? </h2>
                            <ul className="features-list-custom">
                                <li><span><i className='bx bx-check'></i> Gain de temps, plus besoin de recopier vos infos dans un fichier excel / word</span></li>
                                <li><span><i className='bx bx-check'></i> Sécurisez vos données en évitant les pertes d’informations, de documents ou les erreurs de retranscriptions</span></li>
                                <li><span><i className='bx bx-check'></i> Gagnez en agilité en ayant accès à vos données en continu et en temps réel</span></li>
                                <li><span><i className='bx bx-check'></i> Collectez vos données sur le terrain en mode déconnecté sans avoir accès à internet via votre tablette ou votre téléphone</span></li>
                                <li><span><i className='bx bx-check'></i> Échangez rapidement des données entre les collaborateurs</span></li>
                                <li><span><i className='bx bx-check'></i> Réalisez des analyses plus fines, précises et rapides</span></li>
                                <li><span><i className='bx bx-check'></i> Uniformisez les méthodes de travail</span></li>
                                <li><span><i className='bx bx-check'></i> Améliorez la qualité de votre service</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={img1} alt="features" />
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default WhyWispeek